body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accordion-button:focus {
  box-shadow: none !important;
  padding: 0px !important;
}

.accordion-button {
  box-shadow: none !important;
  padding: 0px !important;
}

.list-format {
  margin-bottom: 1rem;
}

.internalLink {
  text-decoration: none;
  color: rgb(3, 37, 117);
  font-weight: bold;
}

.spacerBottom {
  padding-bottom: 12em;
}

.spacerBottom-1 {
  padding-bottom: 1rem;
}

.spacerBottom-2 {
  padding-bottom: 2rem;
}

.spacerBottom-5 {
  padding-bottom: 5rem;
}

.spacerBottomLast {
  padding-top: 3rem;
}

.spacerTop-1 {
  padding-top: 1rem;
}

.spacerTop-2 {
  padding-top: 2rem;
}

.spacerTop-3 {
  padding-top: 3rem;
}

.spacerTop-5 {
  padding-top: 5rem;
}

.spacerTop-13 {
  padding-top: 13rem;
}

.spacerTopLast {
  padding-top: 8rem;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 12%;
  right: 5%;
}

.page-divider-2 {
  height: 2rem;
  background-color: rgb(194, 230, 249);
  border: solid rgb(194, 230, 249);
  border-width: 1px 0;
  /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
}

.page-divider-3 {
  height: 3rem;
  background-color: rgb(194, 230, 249);
  border: solid rgb(194, 230, 249);
  border-width: 1px 0;
  /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
}

.page-divider-4 {
  height: 4rem;
  background-color: rgb(194, 230, 249);
  border: solid rgb(194, 230, 249);
  border-width: 1px 0;
  margin-top: 5%;
  /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
}

.page-divider-5 {
  height: 5rem;
  background-color: rgb(194, 230, 249);
  border: solid rgb(194, 230, 249);
  border-width: 1px 0;
  /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
}

.bg-accordion {
  background-color: rgb(194, 230, 249);
}

.distance {
  height: 15%;
}

.headerImage {
  padding-top: 3em;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 390px;
}

.main-title {
  text-align: center;
}

/* custom css */

h4 {
  font-family: "Helvetica, Verdana, Tahoma, Arial,sans-serif";
  color: rgb(3, 37, 117);
  font-weight: bold;
}

.logo-position {
  display: flex;
  padding-top: 2rem;
  padding-left: 4rem;
}

.edge-2 {
  padding-left: 2rem;
}

.bd-l {
  border-left: 0.5em solid rgb(107, 194, 241);
}

.tooltip {
  background-color: rgb(107, 194, 241);
  color: rgb(4, 26, 135);
}

.accordionIcon {
  width: 2.5vh;
  height: auto;
}

.description,
.explanation {
  font-family: "Helvetica, Verdana, Tahoma, Arial,sans-serif";
  color: rgb(3, 37, 117);
  font-size: 1.2em;
  padding: 0.2em 0.2em;
  line-height: 1.5rem !important;
}

.explanation {
  font-family: "Verdana, Arial, Helvetica, Tahoma, sans-serif";
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.4em 0.6em;
  text-align: center;
}

.content-title {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 2.1em;
  font-weight: bold;
  clear: left;
  margin-top: 5px;
}

.content-sub-title {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 1.5em;
  font-weight: bold;
  clear: left;
  margin-top: 5px;
}

.content-title-sm {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 1.5em;
  font-weight: bold;
  clear: left;
  padding-top: 5px;
}

.donation-title {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 2.4em;
  font-weight: bold;
  clear: left;
  margin-top: 5px;
}

.donation-type {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 2em;
  font-weight: bold;
  clear: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.taxable {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(8, 49, 144);
  font-size: 0.8em;
  font-weight: normal;
  clear: left;
  margin-top: 5px;
}

.shadow-title {
  /* text-shadow: 2px 1px 4px #034464 !important;  */
  text-shadow: none !important;
}

.button-pos {
  /* margin-top: 2%; */
  position: static;
  top: 0;
  width: 100%;
  text-align: center;
}

.btn-text {
  font-size: 1.3em;
  color: rgb(16, 2, 109);
}

.btn-text:hover {
  font-size: 1.4em;
  color: rgb(255, 255, 255) !important;
}

.bd-bottom-2 {
  border-bottom: 2px solid rgb(23, 6, 136);
}

.bd-top-2 {
  border-top: 2px solid rgb(23, 6, 136);
}

/** Ethiopia page */

.ethiopia-header {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  font-size: 1.3em;
  font-weight: bold;
  color: rgb(4, 19, 95);
  flex-wrap: nowrap;
  padding-left: 250px;
}

.ethiopia-font-title {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(4, 19, 95);
  flex-wrap: nowrap;
}

.ethiopia-font-content {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  font-size: 1.2em;
  font-weight: normal;
  color: rgb(4, 19, 95);
  flex-wrap: nowrap;
  padding-left: 3em;
}

.mapSize {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.mapPointer {
  cursor: pointer;
}

.table-width {
  width: 80%;
}

.col-w-40 {
  width: 280px;
}

.col-pl-2 {
  padding-left: 2em;
}

/* about page */

.about-color {
  color: rgb(17, 7, 82);
}

.about-text {
  font-family: "Helvetica, Verdana, Tahoma, Arial,sans-serif";
  font-size: 1.4em;
  color: rgb(9, 5, 125);
  padding-left: 2rem;
}

.donate-text {
  font-family: "Helvetica, Verdana, Tahoma, Arial,sans-serif";
  font-size: 1.4em;
  color: rgb(9, 5, 125);
  padding-left: 2rem;
}

.content-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.about-width {
  width: 50%;
}

/* Contact form */

.form-label {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  color: rgb(4, 47, 148);
  font-size: 1.1em;
  font-weight: bold;
  clear: left;
  margin-top: 0px;
}

.comment {
  width: 100%;
  background-color: rgb(228, 232, 235);
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.contact-width {
  width: 60%;
  margin-top: 2em;
}

.title-text {
  font-family: "Verdana, Arial, Helvetica, Tahoma, sans-serif";
  font-weight: bold;
  font-size: 2em;
}

.contact-color {
  color: rgb(9, 7, 141);
}

.test-border-red {
  border: 2px solid rgb(249, 46, 5);
}

.test-border-green {
  border: 2px solid rgb(13, 180, 49);
}

@media screen and (max-width: 1080px) {
  /* custom css */

  /* Top right text */
  .top-right {
    position: absolute;
    top: 8%;
    right: 6%;
  }

  .page-divider-3 {
    height: 1rem;
    background-color: rgb(194, 230, 249);
    border: solid rgb(194, 230, 249);
    border-width: 1px 0;
    /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
  }

  .page-divider-4 {
    height: 1rem;
    background-color: rgb(194, 230, 249);
    border: solid rgb(194, 230, 249);
    border-width: 1px 0;
    margin-top: 5%;
    /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
  }

  .spacerTop-13 {
    padding-top: 1rem;
  }

  .spacerTop-5 {
    padding-top: 1rem;
  }

  .spacerTop-2 {
    padding-top: 1rem;
  }

  .logo-position {
    padding-top: 2em;
  }

  .description,
  .explanation {
    font-family: "Helvetica, Verdana, Tahoma, Arial,sans-serif";
    color: rgb(3, 37, 117);
    font-size: 1.4em;
    padding: 0.2em 0.2em;
    line-height: 2rem !important;
  }

  .explanation {
    font-family: "Verdana, Arial, Helvetica, Tahoma, sans-serif";
    font-weight: bold;
    font-size: 1.2em;
    padding: 0.4em 0.6em;
    text-align: center;
  }

  .content-title {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    color: rgb(8, 49, 144);
    font-size: 2.1em;
    font-weight: bold;
    clear: left;
    margin-top: 5px;
  }

  .content-title-sm {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    color: rgb(8, 49, 144);
    font-size: 1.5em;
    font-weight: bold;
    clear: left;
    padding-top: 5px;
  }

  .bd-l {
    border-left: none;
  }

  .button-pos {
    /* margin-top: 2%; */
    position: static;
    top: 0;
    width: 100%;
    text-align: center;
  }

  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .shadow {
    text-shadow: none !important;
    /* text-shadow: 6px 6px 10px #3c3e3f !important; */
  }

  .btn-text {
    font-size: 1.3em;
    color: rgb(16, 2, 109);
  }

  .btn-text:hover {
    font-size: 1.4em;
    color: rgb(255, 255, 255) !important;
  }

  /* Contact page format */

  .contact-title {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    color: rgb(4, 47, 148);
    font-size: 1.6em;
    font-weight: bold;
    clear: left;
    margin-top: 0px;
  }

  /** Ethiopia page */

  .ethiopia-header {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    font-size: 1.3em;
    font-weight: bold;
    color: rgb(4, 19, 95);
    flex-wrap: nowrap;
  }

  .ethiopia-font-title {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(4, 19, 95);
    flex-wrap: nowrap;
  }

  .ethiopia-font-content {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    font-size: 1.2em;
    font-weight: normal;
    color: rgb(4, 19, 95);
    flex-wrap: nowrap;
    padding-left: 3em;
  }

  .mapSize {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .table-width {
    width: 100%;
  }
}
