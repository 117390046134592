.Nav {
  width: 100%;
  height: 80px;
  background-color: rgb(40, 106, 168);
  display: flex;
}

.nav-title {
  display: flex;
  font-family: Tahoma, Helvetica, Verdana, Arial, sans-serif !important;
  font-size: 4em !important;
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  /*  text-shadow: 4px 4px 6px #101011; */
}

.nav-link {
  display: inline-block !important;
  padding-top: 0.2em !important;
}

/* The navigation and language links */

.NavigationButtons {
  font-family: Arial, Helvetica, Tahoma, Verdana, sans-serif !important;
  font-size: 1.3rem;
  text-decoration: none;
}

.NavigationButtons > a {
  text-decoration: none;
  margin: 8px;
  color: white;
}
.NavigationButtons > a:hover {
  color: rgb(30, 117, 224);
  transition: all 0.7s ease-in-out;
}
.NavigationButtons > a.active {
  text-decoration: underline;
}

.NavigationButtonModal {
  font-family: Arial, Helvetica, Tahoma, Verdana, sans-serif !important;
  font-size: 1rem;
  display: flex;
}

.NavigationButtonModal > a {
  text-decoration: none;
  margin: 8px;
  color: white;
}
.NavigationButtonModal > a:hover {
  color: rgb(30, 117, 224);
  transition: all 0.7s ease-in-out;
}
.NavigationButtonModal > a.active {
  text-decoration: underline;
}

.position-activity {
  position: absolute;
  right: 550px;
}

.position-projects {
  position: absolute;
  right: 350px;
}

.dropdown-item-font-size {
  font-size: 1.2rem;
}

.position-modal-button {
  position: absolute;
  right: 160px;
  padding-top: 0.4em;
}

.menu-font {
  background-color: rgb(194, 230, 249);
  color: rgb(4, 68, 102);
}

.menu-font:hover {
  background-color: rgb(7, 131, 198);
  color: rgb(151, 212, 245);
}

.LanguageSwitcher a {
  text-decoration: none;
  font-size: 1.1rem;
  color: whitesmoke;
}

.LanguageSwitcher a:hover {
  color: rgb(30, 117, 224);
  transition: all 0.7s ease-in-out;
}

.LanguageSwitcher a.active {
  text-decoration: underline;
}

.position-language {
  position: absolute;
  right: 10px;
  padding-top: 0.2em;
}

.language-margin {
  float: none !important;
}

.spacer-right {
  padding-right: 4em;
}

.spacer-right-dropdown {
  padding-right: 6em;
}

.spacer-big-right {
  padding-right: 100%;
}

.table-info-font {
  font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
  font-size: 1.1em;
  font-weight: bold;
  color: rgb(4, 19, 95);
}

.table-width {
  width: 100%;
}

/* Footer */

.FooterButtons {
  font-family: Arial, Helvetica, Tahoma, Verdana, sans-serif !important;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.FooterButtons > a {
  text-decoration: none;
  margin: 8px;
  color: white;
}
.FooterButtons > a:hover {
  color: rgb(30, 117, 224);
  transition: all 0.7s ease-in-out;
}
.FooterButtons > a.active {
  text-decoration: underline;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

@media screen and (max-width: 1600px) {
  .spacer-right {
    padding-right: 20px !important;
  }

  .spacer-big-right {
    padding-right: 60%;
  }
}

@media screen and (max-width: 1080px) {
  /*The header part*/
  .nav-title {
    font-size: 3em !important;
  }

  .position-activity {
    position: relative;
    right: 0px;
    margin-left: 0rem;
  }

  .position-projects {
    position: relative;
    right: 0px;
    margin-left: 0rem;
  }

  .dropdown-item-font-size {
    font-size: 1.2rem;
  }

  /* The navigation and language links */

  .NavigationButtons {
    font-family: Arial, Helvetica, Tahoma, Verdana, sans-serif !important;
    font-size: 1.6rem;
    text-decoration: none;
  }

  .NavigationButtons > a {
    text-decoration: none;
    margin: 4px;
    color: white;
  }
  .NavigationButtons > a:hover {
    color: rgb(30, 117, 224);
    transition: all 0.7s ease-in-out;
  }
  .NavigationButtons > a.active {
    text-decoration: underline;
  }

  .NavigationButtonModal {
    font-family: Arial, Helvetica, Tahoma, Verdana, sans-serif !important;
    font-size: 1.6rem;
    display: flex;
  }

  .NavigationButtonModal > a {
    text-decoration: none;
    margin: 4px;
    color: white;
  }
  .NavigationButtonModal > a:hover {
    color: rgb(30, 117, 224);
    transition: all 0.7s ease-in-out;
  }
  .NavigationButtonModal > a.active {
    text-decoration: underline;
  }

  .position-modal-button {
    position: relative;
    right: 0;
  }

  .LanguageSwitcher a {
    text-decoration: none;
    font-size: 1.6rem;
    color: whitesmoke;
  }

  .LanguageSwitcher a:hover {
    color: rgb(30, 117, 224);
    transition: all 0.7s ease-in-out;
  }

  .LanguageSwitcher a.active {
    text-decoration: underline;
  }

  .position-language {
    position: relative;
    right: 0px;
  }

  .language-margin {
    float: none !important;
  }

  .spacer-right {
    padding-right: 0em !important;
  }

  .spacer-big-right {
    padding-right: 0;
  }

  /*Footer */

  .FooterButtons {
    font-size: 1.4rem;
  }

  .mt-1 {
    margin-top: 0;
  }

  .mt-2 {
    margin-top: 0;
  }

  .table-width {
    width: 900px;
  }

  .table-info-font {
    font-family: "Helvetica, Verdana, Arial, Tahoma, sans-serif";
    font-size: 0.8em;
    font-weight: bold;
    color: rgb(4, 19, 95);
  }
}
